.spinner {
  border: 30px solid white;
  border-top: 30px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  width: 200px;
  height: 200px;
  animation: spin 1.5s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -100px;
}

.background {
  width: 100%;
  height: 100%;
}

.shoveler-rowitem-static .background {
  width: 200px;
  height: 350px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
