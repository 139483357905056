.leftnav-main-container {
  top: 0;
  left: 0;
  height: 100%;
  width: 44%;
  margin: 0;
  z-index: 10;
}

.leftnav-menu-list,
.leftnav-menulist-expanded,
.leftnav-menulist-collapsed {
  width: 100%;
  height: 100%;
  padding: 0px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.9);
  top: 148px;
}

.leftnav-menulist-expanded {
  height: 100%;
  top: 0;
  z-index: 100;
}

.leftnav-menulist-collapsed {
  top: 148px;
  height: 92px;
  z-index: 10;
}

.leftnav-collapsed-highlight {
  -webkit-box-shadow: 0px 10px 18px 3px rgba(0, 0, 0, 0.44);
  -moz-box-shadow: 0px 10px 18px 3px rgba(0, 0, 0, 0.44);
  box-shadow: 0px 10px 18px 3px rgba(0, 0, 0, 0.44);
  border: solid;
  border-width: 1px;
  border-color: #dadada;
}

.leftnav-menulist-collapsed .leftnav-menu-scrolling-list {
  margin-top: -56px;
}

.leftnav-menu-scrolling-list {
  color: #b9bbbd;
}

.leftnav-list-item-static.leftnav-list-item-chosen.subcat-header {
  top: 148px;
  width: 40%;
  background: none;
  border: none;
}

.leftnav-list-item-static {
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  line-height: 2;
  font-weight: lighter;
  font-size: 1.7em;
  min-height: 90px;
  min-width: 0;
  padding: 0 50px 0 150px;
  overflow: hidden;
  margin-bottom: 2px;
  background-color: rgba(232, 232, 232, 0.1);
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-duration: 0.25s;
  -moz-transition-duration: 0.25s;
  transition-duration: 0.25s;
  display: -webkit-box;
  /* NEW - Chrome */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: block;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  line-clamp: 1;
}

.leftnav-list-item {
  display: flex;
  white-space: nowrap;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}

.leftnav-list-item img {
  margin-right: 15px;
}

.leftnav-list-item-container:first-child {
  margin-top: 56px;
}

.leftnav-list-item-highlighted {
  color: #e8e8e8;
  border-width: 1px 1px 1px 0px;
  background: #000000 url("./images/icon_leftnav_arrowDown.png") no-repeat right 40px center;
}

.leftnav-list-item-container .leftnav-list-item-selected {
  color: #000000;
  background: #ffffff;
}

.leftnav-list-item-chosen {
  border-color: #3f484f;
  border-style: solid;
  border-width: 1px 1px 1px 0px;
  color: #e8e8e8;
  background: #3f484f url("./images/icon_leftnav_arrowDown.png") no-repeat right 40px center;
}

.app-top-bar {
  width: 100%;
  height: 148px;
  background-color: rgba(0, 0, 0, 0.1);
}
