.video-overlay {
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.overlay-holder {
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 200;
  text-align: center;
  padding: 5%;
}

.overlay-holder h1 {
  font-size: 2.5em;
}

.error-message {
  color: white;
  text-align: center;
}

#youtube-player {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

.timer {
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 9000;
}
