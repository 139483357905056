.one-D-details-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.one-d-no-items-container {
  position: absolute;
  display: none;
  top: 300px;
  left: 200px;
  color: #e8e8e8;
  font-size: 3em;
}

#one-D-shoveler-container {
  top: 160px;
  left: 0px;
  width: 100%;
  height: 474px;
  /* Add 100px to the shoveler item height */
  z-index: 20;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
}

.channel-row-container {
  width: 100%;
  height: 100%;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.shoveler-rowitem-static {
  margin-top: 10px;
  margin-left: 59px;
  height: 350px;
  min-width: 466.67px;
  background-color: #8F8F8F;
}

.shoveler-rowitem-static img {
  height: 100%;
  display: inline-block;
}

.thumbnail-holder {
  display: flex;
  white-space: nowrap;
  overflow: scroll;
  height: inherit;
  width: 100%;
  padding-left: 65px;
  scroll-behavior: smooth;
}

.selected {
  outline: 10px solid white;
  border-radius: 1rem;
  box-shadow: 0px 15px 18px 5px rgb(0 0 0 / 44%);
}

.thumbnail-holder::-webkit-scrollbar {
  display: none;
}

.video-title {
  position: inline-block;
  white-space: normal;
  font-size: 0.8em;
  color: #e8e8e8;
}

.channel-spinner {
  position: relative;
  width: 200px;
  height: 474px;
  top: -50px;
  margin-left: 125px;
}

.channel {
  margin-top: 20px;
  margin-bottom: 20px;
}
