input {
  border: none;
  outline: none;
  background: none;
  border-bottom: 3px solid #ffffff;
  width: 600px;
  font-size: 2em;
  caret-color: white;
  color: white;
}

.search {
  margin-top: 50px;
  text-align: center;
}
