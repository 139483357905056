.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #5a656f url("./images/bg_app.png") no-repeat left top;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.overlay * {
  margin: 10px;
  padding: 0;
}

.overlay h1 {
  font-size: 2.5em;
}

.icon {
  width: 250px;
  height: 250px;
}
