#player-controls-container {
  position: absolute;
  width: 100%;
  height: 30%;
  padding: 0 8%;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;
}

#current-timestamp {
  color: rgba(255, 255, 255, 1);
  float: left;
  font-size: 25pt;
}

#total-timestamp {
  color: rgba(255, 255, 255, 1);
  float: right;
  font-size: 25pt;
}

#timeline {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #25435a;
  border-radius: 6px;
  background-color: #707070;
}

#timeline-playhead {
  position: absolute;
  width: 0%;
  height: 10px;
  border: 1px solid #394149;
  margin-top: -1px;
  border-radius: 6px;
  background-color: #e8e8e8;
}

#timeline-container {
  position: relative;
  height: 10px;
  margin-top: 20px;
}

#video-title {
  position: absolute;
  top: 80px;
  height: 100px;
  color: #e8e8e8;
  font-size: 1.5em;
  font-family: "Helvetica Neue Medium", "HelveticaNeue-Medium", Helvetica, Arial,
    sans-serif;
  margin-top: 20px;
}
