.next-button {
  display: inline-block;
  padding: 0.3em 1.2em;
  border-radius: 0.4em;
  font-family: "Helvetica Neue Light", "HelveticaNeue-Light", Helvetica, Arial,
    sans-serif;
  font-size: 1.4em;
  color: #e8e8e8;
  background-color: #4eb5f1;
  text-align: center;
  margin: 0.2em auto;
  border-color: #e8e8e8;
  border-style: solid;
  border-width: 2px 2px 2px 2px;
}

.next-button:active {
  box-shadow: 0px 10px 18px 3px rgba(0, 0, 0, 0.44);
  background-color: #00a2ff;
  border-color: #e8e8e8;
  border-style: solid;
  border-width: 5px 5px 5px 5px;
}
