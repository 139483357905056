* {
  box-sizing: Border-box;
}

body::-webkit-scrollbar {
  display: none;
}

div:focus {
  outline: none;
}

body {
  padding: 0;
  margin: 0;
  font-size: 20pt;
  font-family: "Helvetica Neue Light", "HelveticaNeue-Light", Helvetica, Arial,
    sans-serif;
  background: #5a656f url("./images/bg_app.png") no-repeat left top;
  background-size: 100% 100%;
}

#root {
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: scroll;
}

h1 {
  font-family: "Helvetica Neue Light", "HelveticaNeue-Light", Helvetica, Arial,
    sans-serif;
  font-size: 1.5em;
  color: #e8e8e8;
}

.title-icon {
  width: 90px;
  height: 90px;
  fill: black;
}

.playlist-icon {
  border: 5px solid white;
  object-fit: cover;
}

.channel-icon {
  border: 5px solid white;
  border-radius: 50px;
}

.shoveler-header {
  display: flex;
  flex-direction: row;
  margin-left: 125px;
}

.shoveler-header h1 {
  margin-bottom: 0;
}

.app-spinner {
  position: relative;
  left: calc(50% - 100px);
  height: 200px;
  width: 200px;
}
