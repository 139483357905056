.placeholder {
  margin-top: 30px;
  margin-left: 59px;
  height: 350px;
  min-width: 466.67px;
  background-color: #8F8F8F;
}

.loading-shoveler-holder {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 490px;
  margin-left: 66px;
}
