#one-D-grid-container {
  top: 160px;
  left: 0px;
  width: 100%;
  height: 100%;
  /* Add 100px to the shoveler item height */
  z-index: 20;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
}

.grid-rowitem-static {
  margin: 10px 30px 10px 30px;
  width: 100%;
}

.grid-rowitem-static img {
  width: 100%;
  display: inline-block;
}

.grid-thumbnail-holder {
  display: inline-grid;
  grid-template-columns: 20% 20% 20% 20%;
  column-gap: 60px;
  white-space: nowrap;
  flex-wrap: wrap;
  justify-content: center;
  overflow: scroll;
  height: inherit;
  width: 100%;
  scroll-behavior: smooth;
}

.grid-thumbnail-holder::-webkit-scrollbar {
  display: none;
}
